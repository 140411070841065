import { Component, input, viewChild } from '@angular/core';
import { FlyModalComponent, FlyModalModule, ModalType } from '@garmin-avcloud/avcloud-ui-common/modal';

@Component({
    selector: 'pilot-unavailable-feature-modal',
    imports: [FlyModalModule],
    templateUrl: './unavailable-feature-modal.component.html',
    styleUrl: './unavailable-feature-modal.component.scss'
})
export class UnavailableFeatureModalComponent {
  readonly modal = viewChild.required(FlyModalComponent);
  title = input.required<string>();
  readonly ModalType = ModalType;

  show(): void {
    this.modal().show();
  }
}
