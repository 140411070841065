// index.ts
import { clone } from "@turf/clone";
import { center } from "@turf/center";
import { centroid } from "@turf/centroid";
import { bbox as turfBBox } from "@turf/bbox";
import { rhumbBearing } from "@turf/rhumb-bearing";
import { rhumbDistance } from "@turf/rhumb-distance";
import { rhumbDestination } from "@turf/rhumb-destination";
import { coordEach, featureEach } from "@turf/meta";
import { point, isObject } from "@turf/helpers";
import { getCoord, getCoords, getType } from "@turf/invariant";
function transformScale(geojson, factor, options) {
  options = options || {};
  if (!isObject(options)) throw new Error("options is invalid");
  const origin = options.origin || "centroid";
  const mutate = options.mutate || false;
  if (!geojson) throw new Error("geojson required");
  if (typeof factor !== "number" || factor <= 0) throw new Error("invalid factor");
  const originIsPoint = Array.isArray(origin) || typeof origin === "object";
  if (mutate !== true) geojson = clone(geojson);
  if (geojson.type === "FeatureCollection" && !originIsPoint) {
    featureEach(geojson, function (feature, index) {
      geojson.features[index] = scale(feature, factor, origin);
    });
    return geojson;
  }
  return scale(geojson, factor, origin);
}
function scale(feature, factor, origin) {
  const isPoint = getType(feature) === "Point";
  const originCoord = defineOrigin(feature, origin);
  if (factor === 1 || isPoint) return feature;
  coordEach(feature, function (coord) {
    const originalDistance = rhumbDistance(originCoord, coord);
    const bearing = rhumbBearing(originCoord, coord);
    const newDistance = originalDistance * factor;
    const newCoord = getCoords(rhumbDestination(originCoord, newDistance, bearing));
    coord[0] = newCoord[0];
    coord[1] = newCoord[1];
    if (coord.length === 3) coord[2] *= factor;
  });
  delete feature.bbox;
  return feature;
}
function defineOrigin(geojson, origin) {
  if (origin === void 0 || origin === null) origin = "centroid";
  if (Array.isArray(origin) || typeof origin === "object") return getCoord(origin);
  const bbox = geojson.bbox ? geojson.bbox : turfBBox(geojson, {
    recompute: true
  });
  const west = bbox[0];
  const south = bbox[1];
  const east = bbox[2];
  const north = bbox[3];
  switch (origin) {
    case "sw":
    // @ts-expect-error undocumented, to be removed for v8 #techdebt
    case "southwest":
    // @ts-expect-error undocumented, to be removed for v8 #techdebt
    case "westsouth":
    // @ts-expect-error undocumented, to be removed for v8 #techdebt
    case "bottomleft":
      return point([west, south]);
    case "se":
    // @ts-expect-error undocumented, to be removed for v8 #techdebt
    case "southeast":
    // @ts-expect-error undocumented, to be removed for v8 #techdebt
    case "eastsouth":
    // @ts-expect-error undocumented, to be removed for v8 #techdebt
    case "bottomright":
      return point([east, south]);
    case "nw":
    // @ts-expect-error undocumented, to be removed for v8 #techdebt
    case "northwest":
    // @ts-expect-error undocumented, to be removed for v8 #techdebt
    case "westnorth":
    // @ts-expect-error undocumented, to be removed for v8 #techdebt
    case "topleft":
      return point([west, north]);
    case "ne":
    // @ts-expect-error undocumented, to be removed for v8 #techdebt
    case "northeast":
    // @ts-expect-error undocumented, to be removed for v8 #techdebt
    case "eastnorth":
    // @ts-expect-error undocumented, to be removed for v8 #techdebt
    case "topright":
      return point([east, north]);
    case "center":
      return center(geojson);
    case void 0:
    case null:
    case "centroid":
      return centroid(geojson);
    default:
      throw new Error("invalid origin");
  }
}
var turf_transform_scale_default = transformScale;
export { turf_transform_scale_default as default, transformScale };
