import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { environment } from '@environment';
import {
  SUBSCRIPTION_GUARD_CONFIG_TOKEN,
  SUBSCRIPTION_SERVICE_CONFIG_TOKEN,
  type SubscriptionGuardConfig,
  type SubscriptionServiceConfig,
} from '@garmin-avcloud/avcloud-web-utils';
import { redirectDevToTest } from './provider.utils';

export const provideSubscriptions = (): EnvironmentProviders =>
  makeEnvironmentProviders([
    {
      provide: SUBSCRIPTION_GUARD_CONFIG_TOKEN,
      useValue: {
        redirectRoute: '',
      } as SubscriptionGuardConfig,
    },
    {
      provide: SUBSCRIPTION_SERVICE_CONFIG_TOKEN,
      useValue: {
        environment: redirectDevToTest(environment.name),
      } as SubscriptionServiceConfig,
    },
  ]);
