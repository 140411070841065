import { Component, EventEmitter, Output } from '@angular/core';
import { NgClass } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'pilot-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    imports: [NgClass, RouterLink]
})
export class HeaderComponent {
  @Output() readonly sidebarVisibilityChange: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  showSidebar: boolean = false;

  toggleSidebar(): void {
    this.showSidebar = !this.showSidebar;
    this.sidebarVisibilityChange.emit(this.showSidebar);
  }
}
