import { type EnvironmentProviders, ErrorHandler, makeEnvironmentProviders } from '@angular/core';
import { Router } from '@angular/router';
import { TraceService, createErrorHandler } from '@sentry/angular';

export const provideSentry = (): EnvironmentProviders =>
  makeEnvironmentProviders([
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
  ]);
