import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { environment } from '@environment';
import { FboServiceConfiguration } from '@generated/fbo-service';
import { FlightOrchestratorServiceConfiguration } from '@generated/flight-orchestrator-service';
import { FlightRouteServiceConfiguration } from '@generated/flight-route-service';

export const provideGeneratedApiConfigs = (): EnvironmentProviders =>
  makeEnvironmentProviders([
    {
      provide: FboServiceConfiguration,
      useValue: new FboServiceConfiguration({
        basePath: `${environment.garmin.aviation.servicesHost}/fboService`,
      }),
    },
    {
      provide: FlightRouteServiceConfiguration,
      useValue: new FlightRouteServiceConfiguration({
        basePath: `${environment.garmin.aviation.servicesHost}/flightRouteService`,
      }),
    },
    {
      provide: FlightOrchestratorServiceConfiguration,
      useValue: new FlightOrchestratorServiceConfiguration({
        basePath: `${environment.garmin.aviation.servicesHost}/flightOrchestratorService`,
      }),
    },
  ]);
