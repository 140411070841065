import * as i2 from '@angular/cdk/scrolling';
import { ScrollingModule } from '@angular/cdk/scrolling';
import * as i1$1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Directive, Input, Component, EventEmitter, Output, inject, ViewChild, NgModule } from '@angular/core';
import { Breakpoints } from '@garmin-avcloud/avcloud-ui-common/style-variables';
import { distinctUntilChanged } from 'rxjs';
import * as i1 from '@angular/cdk/layout';
import { ZIndexService } from '@garmin-avcloud/avcloud-ui-common/shared';
const _c0 = [[["", "slot", "content"]], [["", "slot", "footer"]], [["", "slot", "header"]]];
const _c1 = ["[slot=content]", "[slot=footer]", "[slot=header]"];
const _c2 = a0 => ({
  "top": a0
});
function HeaderLayoutComponent_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0, 5);
  }
  if (rf & 2) {
    i0.ɵɵnextContext();
    const nav_r1 = i0.ɵɵreference(8);
    i0.ɵɵproperty("ngTemplateOutlet", nav_r1);
  }
}
function HeaderLayoutComponent_ng_container_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0, 5);
  }
  if (rf & 2) {
    i0.ɵɵnextContext();
    const nav_r1 = i0.ɵɵreference(8);
    i0.ɵɵproperty("ngTemplateOutlet", nav_r1);
  }
}
function HeaderLayoutComponent_ng_template_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 6);
    i0.ɵɵprojection(1, 2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(1, _c2, ctx_r1.view === ctx_r1.ViewTypes.Large));
  }
}
const _c3 = [[["", "slot", "content"]], [["", "slot", "footer"]], [["", "slot", "header"]], [["", "slot", "sidebar"]]];
const _c4 = ["[slot=content]", "[slot=footer]", "[slot=header]", "[slot=sidebar]"];
function HeaderWithLeftSidebarLayoutComponent_div_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 8);
    i0.ɵɵelementContainer(1, 9);
    i0.ɵɵelementStart(2, "button", 10);
    i0.ɵɵlistener("click", function HeaderWithLeftSidebarLayoutComponent_div_0_Template_button_click_2_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.setSidebarOpened(false));
    });
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    const sidebar_r3 = i0.ɵɵreference(13);
    i0.ɵɵproperty("hidden", !ctx_r1.sidebarOpened);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", sidebar_r3);
  }
}
function HeaderWithLeftSidebarLayoutComponent_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0, 9);
  }
  if (rf & 2) {
    i0.ɵɵnextContext();
    const nav_r4 = i0.ɵɵreference(11);
    i0.ɵɵproperty("ngTemplateOutlet", nav_r4);
  }
}
function HeaderWithLeftSidebarLayoutComponent_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0, 9);
  }
  if (rf & 2) {
    i0.ɵɵnextContext();
    const sidebar_r3 = i0.ɵɵreference(13);
    i0.ɵɵproperty("ngTemplateOutlet", sidebar_r3);
  }
}
function HeaderWithLeftSidebarLayoutComponent_ng_container_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0, 9);
  }
  if (rf & 2) {
    i0.ɵɵnextContext();
    const nav_r4 = i0.ɵɵreference(11);
    i0.ɵɵproperty("ngTemplateOutlet", nav_r4);
  }
}
function HeaderWithLeftSidebarLayoutComponent_ng_template_10_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 11);
    i0.ɵɵprojection(1, 2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(1, _c2, ctx_r1.view === ctx_r1.ViewTypes.Large));
  }
}
function HeaderWithLeftSidebarLayoutComponent_ng_template_12_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 12);
    i0.ɵɵprojection(1, 3);
    i0.ɵɵelementEnd();
  }
}
const _c5 = ["container"];
const _c6 = ["header"];
const _c7 = [[["", "slot", "content"]], [["", "slot", "header"]], [["", "slot", "sidebar"]]];
const _c8 = ["[slot=content]", "[slot=header]", "[slot=sidebar]"];
const _c9 = (a0, a1) => ({
  "mobile-layout": a0,
  "sidebar-visible": a1
});
function SidebarLayoutComponent_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelementContainer(1, 8);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵnextContext();
    const sidebar_r1 = i0.ɵɵreference(8);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", sidebar_r1);
  }
}
function SidebarLayoutComponent_div_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 9, 2);
    i0.ɵɵprojection(2, 1);
    i0.ɵɵelementEnd();
  }
}
function SidebarLayoutComponent_div_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 10);
    i0.ɵɵelementContainer(1, 8);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    const sidebar_r1 = i0.ɵɵreference(8);
    i0.ɵɵstyleProp("height", ctx_r1.sidebarHeight, "px")("z-index", ctx_r1.zIndex);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", sidebar_r1);
  }
}
function SidebarLayoutComponent_ng_template_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0, 2);
  }
}
var View;
(function (View) {
  View[View["Small"] = 0] = "Small";
  View[View["Large"] = 1] = "Large";
})(View || (View = {}));
class LayoutDirective {
  constructor(breakpointObserver) {
    this.breakpointObserver = breakpointObserver;
    this.breakpoint = Breakpoints.SmallScreenMaxWidth;
    this.ViewTypes = View;
    this.view = View.Small;
  }
  ngOnInit() {
    this.breakpointSubscription = this.breakpointObserver.observe([this.breakpoint]).pipe(distinctUntilChanged()).subscribe(({
      matches
    }) => this.view = matches ? View.Small : View.Large);
  }
  ngOnDestroy() {
    this.breakpointSubscription.unsubscribe();
  }
  static {
    this.ɵfac = function LayoutDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LayoutDirective)(i0.ɵɵdirectiveInject(i1.BreakpointObserver));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: LayoutDirective,
      inputs: {
        breakpoint: "breakpoint"
      },
      standalone: false
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LayoutDirective, [{
    type: Directive
  }], () => [{
    type: i1.BreakpointObserver
  }], {
    breakpoint: [{
      type: Input
    }]
  });
})();
class HeaderLayoutComponent extends LayoutDirective {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵHeaderLayoutComponent_BaseFactory;
      return function HeaderLayoutComponent_Factory(__ngFactoryType__) {
        return (ɵHeaderLayoutComponent_BaseFactory || (ɵHeaderLayoutComponent_BaseFactory = i0.ɵɵgetInheritedFactory(HeaderLayoutComponent)))(__ngFactoryType__ || HeaderLayoutComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: HeaderLayoutComponent,
      selectors: [["fly-header-layout"]],
      standalone: false,
      features: [i0.ɵɵInheritDefinitionFeature],
      ngContentSelectors: _c1,
      decls: 9,
      vars: 2,
      consts: [["nav", ""], [1, "container"], [3, "ngTemplateOutlet", 4, "ngIf"], [1, "content"], [1, "footer"], [3, "ngTemplateOutlet"], [1, "nav", 3, "ngClass"]],
      template: function HeaderLayoutComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵelementStart(0, "div", 1);
          i0.ɵɵtemplate(1, HeaderLayoutComponent_ng_container_1_Template, 1, 1, "ng-container", 2);
          i0.ɵɵelementStart(2, "div", 3);
          i0.ɵɵprojection(3);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(4, "div", 4);
          i0.ɵɵprojection(5, 1);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(6, HeaderLayoutComponent_ng_container_6_Template, 1, 1, "ng-container", 2);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(7, HeaderLayoutComponent_ng_template_7_Template, 2, 3, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.view === ctx.ViewTypes.Large);
          i0.ɵɵadvance(5);
          i0.ɵɵproperty("ngIf", ctx.view === ctx.ViewTypes.Small);
        }
      },
      dependencies: [i1$1.NgClass, i1$1.NgIf, i1$1.NgTemplateOutlet],
      styles: [".container[_ngcontent-%COMP%]{display:flex;flex-flow:column nowrap;align-items:stretch;min-height:100vh}.container[_ngcontent-%COMP%] > div[_ngcontent-%COMP%]{flex-basis:0}.content[_ngcontent-%COMP%]{flex-grow:1;position:relative}.nav[_ngcontent-%COMP%]{z-index:1;position:sticky;bottom:0}.nav.top[_ngcontent-%COMP%]{top:0;bottom:unset}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HeaderLayoutComponent, [{
    type: Component,
    args: [{
      selector: 'fly-header-layout',
      template: "<div class=\"container\">\n  <ng-container\n    *ngIf=\"view === ViewTypes.Large\"\n    [ngTemplateOutlet]=\"nav\">\n  </ng-container>\n  <div class=\"content\">\n    <ng-content select=\"[slot=content]\"></ng-content>\n  </div>\n  <div class=\"footer\">\n    <ng-content select=\"[slot=footer]\"></ng-content>\n  </div>\n  <ng-container\n    *ngIf=\"view === ViewTypes.Small\"\n    [ngTemplateOutlet]=\"nav\">\n  </ng-container>\n</div>\n\n<ng-template #nav>\n  <div\n    [ngClass]=\"{'top': view === ViewTypes.Large}\"\n    class=\"nav\">\n    <ng-content select=\"[slot=header]\"></ng-content>\n  </div>\n</ng-template>\n",
      styles: [".container{display:flex;flex-flow:column nowrap;align-items:stretch;min-height:100vh}.container>div{flex-basis:0}.content{flex-grow:1;position:relative}.nav{z-index:1;position:sticky;bottom:0}.nav.top{top:0;bottom:unset}\n"]
    }]
  }], null, null);
})();
class HeaderWithLeftSidebarLayoutComponent extends LayoutDirective {
  constructor() {
    super(...arguments);
    this.sidebarOpened = false;
    this.sidebarOpenedChange = new EventEmitter();
  }
  setSidebarOpened(sidebarOpened) {
    this.sidebarOpened = sidebarOpened;
    this.sidebarOpenedChange.emit(sidebarOpened);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵHeaderWithLeftSidebarLayoutComponent_BaseFactory;
      return function HeaderWithLeftSidebarLayoutComponent_Factory(__ngFactoryType__) {
        return (ɵHeaderWithLeftSidebarLayoutComponent_BaseFactory || (ɵHeaderWithLeftSidebarLayoutComponent_BaseFactory = i0.ɵɵgetInheritedFactory(HeaderWithLeftSidebarLayoutComponent)))(__ngFactoryType__ || HeaderWithLeftSidebarLayoutComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: HeaderWithLeftSidebarLayoutComponent,
      selectors: [["fly-header-with-left-sidebar-layout"]],
      inputs: {
        sidebarOpened: "sidebarOpened"
      },
      outputs: {
        sidebarOpenedChange: "sidebarOpenedChange"
      },
      standalone: false,
      features: [i0.ɵɵInheritDefinitionFeature],
      ngContentSelectors: _c4,
      decls: 14,
      vars: 4,
      consts: [["nav", ""], ["sidebar", ""], ["class", "drawer-wrapper", 3, "hidden", 4, "ngIf"], [1, "container"], [3, "ngTemplateOutlet", 4, "ngIf"], [1, "content"], [1, "flex-grow"], [1, "footer"], [1, "drawer-wrapper", 3, "hidden"], [3, "ngTemplateOutlet"], ["aria-label", "Close Sidebar", 1, "content-overlay", "flex-grow", 3, "click"], [1, "nav", 3, "ngClass"], [1, "sidebar"]],
      template: function HeaderWithLeftSidebarLayoutComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c3);
          i0.ɵɵtemplate(0, HeaderWithLeftSidebarLayoutComponent_div_0_Template, 3, 2, "div", 2);
          i0.ɵɵelementStart(1, "div", 3);
          i0.ɵɵtemplate(2, HeaderWithLeftSidebarLayoutComponent_ng_container_2_Template, 1, 1, "ng-container", 4)(3, HeaderWithLeftSidebarLayoutComponent_ng_container_3_Template, 1, 1, "ng-container", 4);
          i0.ɵɵelementStart(4, "div", 5)(5, "div", 6);
          i0.ɵɵprojection(6);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(7, "div", 7);
          i0.ɵɵprojection(8, 1);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(9, HeaderWithLeftSidebarLayoutComponent_ng_container_9_Template, 1, 1, "ng-container", 4);
          i0.ɵɵelementEnd()();
          i0.ɵɵtemplate(10, HeaderWithLeftSidebarLayoutComponent_ng_template_10_Template, 2, 3, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor)(12, HeaderWithLeftSidebarLayoutComponent_ng_template_12_Template, 2, 0, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", ctx.view === ctx.ViewTypes.Small);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", ctx.view === ctx.ViewTypes.Large);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.view === ctx.ViewTypes.Large);
          i0.ɵɵadvance(6);
          i0.ɵɵproperty("ngIf", ctx.view === ctx.ViewTypes.Small);
        }
      },
      dependencies: [i1$1.NgClass, i1$1.NgIf, i1$1.NgTemplateOutlet],
      styles: [".drawer-wrapper[_ngcontent-%COMP%]{position:fixed;top:0;left:0;z-index:2;display:flex;flex-flow:row nowrap;width:100vw;height:100vh}.sidebar[_ngcontent-%COMP%]{grid-area:sidebar;overflow:auto;background-color:var(--fly-header-with-left-sidebar-layout-background-color)}.content-overlay[_ngcontent-%COMP%]{padding:0;margin:0;border:0;opacity:0}.nav[_ngcontent-%COMP%]{grid-area:nav;position:sticky;bottom:0}.nav.top[_ngcontent-%COMP%]{top:0;bottom:unset}.content[_ngcontent-%COMP%]{grid-area:content;display:flex;flex-flow:column nowrap;height:100%;overflow:auto}.container[_ngcontent-%COMP%]{display:grid;height:100vh;overflow:hidden;grid-auto-columns:minmax(min-content,max-content) auto;grid-auto-rows:minmax(min-content,max-content) auto;grid-template-areas:\"nav nav\" \"sidebar content\"}.flex-grow[_ngcontent-%COMP%]{flex-grow:1}[hidden][_ngcontent-%COMP%]{display:none}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HeaderWithLeftSidebarLayoutComponent, [{
    type: Component,
    args: [{
      selector: 'fly-header-with-left-sidebar-layout',
      template: "<div\n  class=\"drawer-wrapper\"\n  [hidden]=\"!sidebarOpened\"\n  *ngIf=\"view === ViewTypes.Small\">\n  <ng-container [ngTemplateOutlet]=\"sidebar\"></ng-container>\n  <button\n    aria-label=\"Close Sidebar\"\n    class=\"content-overlay flex-grow\"\n    (click)=\"setSidebarOpened(false)\">\n  </button>\n</div>\n\n<div class=\"container\">\n  <ng-container\n    *ngIf=\"view === ViewTypes.Large\"\n    [ngTemplateOutlet]=\"nav\">\n  </ng-container>\n  <ng-container\n    *ngIf=\"view === ViewTypes.Large\"\n    [ngTemplateOutlet]=\"sidebar\">\n  </ng-container>\n  <div class=\"content\">\n    <div class=\"flex-grow\">\n      <ng-content select=\"[slot=content]\"></ng-content>\n    </div>\n    <div class=\"footer\">\n      <ng-content select=\"[slot=footer]\"></ng-content>\n    </div>\n    <ng-container\n      *ngIf=\"view === ViewTypes.Small\"\n      [ngTemplateOutlet]=\"nav\">\n    </ng-container>\n  </div>\n</div>\n\n<ng-template #nav>\n  <div\n    [ngClass]=\"{'top': view === ViewTypes.Large}\"\n    class=\"nav\">\n    <ng-content select=\"[slot=header]\"></ng-content>\n  </div>\n</ng-template>\n\n<ng-template #sidebar>\n  <div class=\"sidebar\">\n    <ng-content select=\"[slot=sidebar]\"></ng-content>\n  </div>\n</ng-template>\n",
      styles: [".drawer-wrapper{position:fixed;top:0;left:0;z-index:2;display:flex;flex-flow:row nowrap;width:100vw;height:100vh}.sidebar{grid-area:sidebar;overflow:auto;background-color:var(--fly-header-with-left-sidebar-layout-background-color)}.content-overlay{padding:0;margin:0;border:0;opacity:0}.nav{grid-area:nav;position:sticky;bottom:0}.nav.top{top:0;bottom:unset}.content{grid-area:content;display:flex;flex-flow:column nowrap;height:100%;overflow:auto}.container{display:grid;height:100vh;overflow:hidden;grid-auto-columns:minmax(min-content,max-content) auto;grid-auto-rows:minmax(min-content,max-content) auto;grid-template-areas:\"nav nav\" \"sidebar content\"}.flex-grow{flex-grow:1}[hidden]{display:none}\n"]
    }]
  }], null, {
    sidebarOpened: [{
      type: Input
    }],
    sidebarOpenedChange: [{
      type: Output
    }]
  });
})();
class SidebarLayoutComponent extends LayoutDirective {
  constructor() {
    super(...arguments);
    this.containerResizeObserver = new ResizeObserver(() => {
      this.calculateSidebarHeight();
    });
    this.zIndexService = inject(ZIndexService);
    this._showSidebar = true;
  }
  get showSidebar() {
    return this._showSidebar;
  }
  set showSidebar(display) {
    this._showSidebar = display;
    if (display) {
      this.zIndex = this.zIndexService.nextZIndex();
    }
  }
  ngAfterViewInit() {
    this.calculateSidebarHeight();
    this.containerResizeObserver.observe(this.container?.nativeElement);
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    this.containerResizeObserver?.disconnect();
  }
  calculateSidebarHeight() {
    const containerHeight = this.container?.nativeElement?.getBoundingClientRect().height ?? 0;
    const headerHeight = this.header?.nativeElement?.getBoundingClientRect().height ?? 0;
    this.sidebarHeight = containerHeight - headerHeight;
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵSidebarLayoutComponent_BaseFactory;
      return function SidebarLayoutComponent_Factory(__ngFactoryType__) {
        return (ɵSidebarLayoutComponent_BaseFactory || (ɵSidebarLayoutComponent_BaseFactory = i0.ɵɵgetInheritedFactory(SidebarLayoutComponent)))(__ngFactoryType__ || SidebarLayoutComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SidebarLayoutComponent,
      selectors: [["fly-left-sidebar-layout"]],
      viewQuery: function SidebarLayoutComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c5, 5);
          i0.ɵɵviewQuery(_c6, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.container = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.header = _t.first);
        }
      },
      inputs: {
        showSidebar: "showSidebar"
      },
      standalone: false,
      features: [i0.ɵɵInheritDefinitionFeature],
      ngContentSelectors: _c8,
      decls: 9,
      vars: 7,
      consts: [["container", ""], ["sidebar", ""], ["header", ""], [1, "container", 3, "ngClass"], [4, "ngIf"], ["class", "header", 4, "ngIf"], ["cdkScrollable", "", 1, "content"], ["class", "sidebar-mobile-layout", 3, "height", "z-index", 4, "ngIf"], [3, "ngTemplateOutlet"], [1, "header"], [1, "sidebar-mobile-layout"]],
      template: function SidebarLayoutComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c7);
          i0.ɵɵelementStart(0, "div", 3, 0);
          i0.ɵɵtemplate(2, SidebarLayoutComponent_div_2_Template, 2, 1, "div", 4)(3, SidebarLayoutComponent_div_3_Template, 3, 0, "div", 5);
          i0.ɵɵelementStart(4, "div", 6);
          i0.ɵɵtemplate(5, SidebarLayoutComponent_div_5_Template, 2, 5, "div", 7);
          i0.ɵɵprojection(6);
          i0.ɵɵelementEnd()();
          i0.ɵɵtemplate(7, SidebarLayoutComponent_ng_template_7_Template, 1, 0, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(4, _c9, ctx.view === ctx.ViewTypes.Small, ctx.showSidebar));
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", ctx.view !== ctx.ViewTypes.Small);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.view === ctx.ViewTypes.Small);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", ctx.showSidebar && ctx.view === ctx.ViewTypes.Small);
        }
      },
      dependencies: [i1$1.NgClass, i1$1.NgIf, i1$1.NgTemplateOutlet, i2.CdkScrollable],
      styles: [".container[_ngcontent-%COMP%]{display:flex;height:100dvh;overflow:hidden}.container.mobile-layout[_ngcontent-%COMP%]{flex-direction:column}.sidebar-mobile-layout[_ngcontent-%COMP%]{position:fixed;left:0;z-index:1}.header[_ngcontent-%COMP%]{position:sticky;top:0}.content[_ngcontent-%COMP%]{display:flex;flex-flow:column nowrap;flex-grow:1;height:100%;overflow:auto}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SidebarLayoutComponent, [{
    type: Component,
    args: [{
      selector: 'fly-left-sidebar-layout',
      template: "<div\n  #container\n  [ngClass]=\"{'mobile-layout': view === ViewTypes.Small, 'sidebar-visible': showSidebar}\"\n  class=\"container\">\n  <div *ngIf=\"view !== ViewTypes.Small\">\n    <ng-container [ngTemplateOutlet]=\"sidebar\"></ng-container>\n  </div>\n  <div\n    #header\n    *ngIf=\"view === ViewTypes.Small\"\n    class=\"header\">\n    <ng-content select=\"[slot=header]\"></ng-content>\n  </div>\n  <div class=\"content\"\n    cdkScrollable>\n    <div\n      *ngIf=\"showSidebar && view === ViewTypes.Small\"\n      class=\"sidebar-mobile-layout\"\n      [style.height.px]=\"sidebarHeight\"\n      [style.z-index]=\"zIndex\">\n      <ng-container [ngTemplateOutlet]=\"sidebar\"></ng-container>\n    </div>\n    <ng-content select=\"[slot=content]\"></ng-content>\n  </div>\n</div>\n\n<ng-template #sidebar>\n  <ng-content select=\"[slot=sidebar]\"></ng-content>\n</ng-template>\n",
      styles: [".container{display:flex;height:100dvh;overflow:hidden}.container.mobile-layout{flex-direction:column}.sidebar-mobile-layout{position:fixed;left:0;z-index:1}.header{position:sticky;top:0}.content{display:flex;flex-flow:column nowrap;flex-grow:1;height:100%;overflow:auto}\n"]
    }]
  }], null, {
    container: [{
      type: ViewChild,
      args: ['container']
    }],
    header: [{
      type: ViewChild,
      args: ['header']
    }],
    showSidebar: [{
      type: Input
    }]
  });
})();
class FlyLayoutsModule {
  static {
    this.ɵfac = function FlyLayoutsModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FlyLayoutsModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: FlyLayoutsModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, ScrollingModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlyLayoutsModule, [{
    type: NgModule,
    args: [{
      declarations: [HeaderLayoutComponent, HeaderWithLeftSidebarLayoutComponent, SidebarLayoutComponent],
      imports: [CommonModule, ScrollingModule],
      exports: [HeaderLayoutComponent, HeaderWithLeftSidebarLayoutComponent, SidebarLayoutComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FlyLayoutsModule, HeaderLayoutComponent, HeaderWithLeftSidebarLayoutComponent, SidebarLayoutComponent };
