import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { environment } from '@environment';
import {
  AUTH_GUARD_CONFIG_TOKEN,
  AUTH_SERVICE_CONFIG_TOKEN,
  AuthGuard,
  type AuthGuardConfig,
  type AuthServiceConfig,
} from '@garmin-avcloud/avcloud-web-utils';
import { redirectDevToTest } from './provider.utils';

export const provideAuth = (): EnvironmentProviders =>
  makeEnvironmentProviders([
    AuthGuard,
    {
      provide: AUTH_GUARD_CONFIG_TOKEN,
      useValue: {
        redirectRoute: '',
      } as AuthGuardConfig,
    },
    {
      provide: AUTH_SERVICE_CONFIG_TOKEN,
      useValue: {
        environment: redirectDevToTest(environment.name),
        clientId: 'AVC_PILOT_WEB_USER',
      } as AuthServiceConfig,
    },
  ]);
