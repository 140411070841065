// This function redirects the 'LOCAL'/'DEV' frontend to use 'TEST' backend services
export const redirectDevToTest = (name: 'LOCAL' | 'DEV' | 'TEST' | 'STAGE' | 'PROD'): 'TEST' | 'STAGE' | 'PROD' => {
  switch (name) {
    case 'DEV':
    case 'LOCAL':
      return 'TEST';
    default:
      return name;
  }
};
