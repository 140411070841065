import { DOCUMENT } from '@angular/common';
import { Component, computed, inject, OnInit, signal, ViewChild } from '@angular/core';
import { FlyModalComponent, ModalType, FlyModalModule } from '@garmin-avcloud/avcloud-ui-common/modal';
import { AuthService } from '@garmin-avcloud/avcloud-web-utils';
import { FlyButtonModule } from '@garmin-avcloud/avcloud-ui-common/button';

@Component({
    selector: 'pilot-subscription-required-modal',
    templateUrl: './subscription-required-modal.component.html',
    styleUrls: ['./subscription-required-modal.component.scss'],
    imports: [FlyModalModule, FlyButtonModule]
})
export class SubscriptionRequiredModalComponent implements OnInit {
  @ViewChild('modal') readonly modal: FlyModalComponent;

  private readonly document = inject(DOCUMENT);
  private readonly authService = inject(AuthService);
  readonly isAuthenticated = signal(true);
  readonly ModalType = ModalType;

  readonly modalText = computed(() => {
    if (this.isAuthenticated()) {
      return 'Continue to Garmin.com to purchase a subscription or learn more about Garmin Pilot Web';
    }
    return 'Continue to Garmin.com to purchase a subscription or Sign In to continue';
  });

  ngOnInit(): void {
    this.authService.isAuthenticated().subscribe((isAuthenticated) => {
      this.isAuthenticated.set(isAuthenticated);
    });
  }

  show(): void {
    this.modal.show();
  }

  learnAboutPilotWeb(): void {
    this.document.location.href = 'https://www.garmin.com/aviation/garminpilot/overview/';
  }

  signIn(): void {
    this.authService.redirectToSsoLoginPortal();
  }
}
