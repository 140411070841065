import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { environment } from '@environment';
import {
  CUSTOMER_FREEFORM_SETTING_SERVICE_CONFIG_TOKEN,
  CUSTOMER_OPTION_VALUE_SETTING_SERVICE_CONFIG_TOKEN,
  CUSTOMER_SERVICE_CONFIG_TOKEN,
  type CustomerFreeformSettingServiceConfig,
  type CustomerOptionValueSettingServiceConfig,
  type CustomerServiceConfig,
  SETTING_SERVICE_CONFIG_TOKEN,
  type SettingServiceConfig,
} from '@garmin-avcloud/avcloud-web-utils';
import { redirectDevToTest } from './provider.utils';

export const provideSettings = (): EnvironmentProviders =>
  makeEnvironmentProviders([
    {
      provide: CUSTOMER_SERVICE_CONFIG_TOKEN,
      useValue: {
        environment: redirectDevToTest(environment.name),
        clientId: 'AVC_PILOT_WEB_USER',
      } as CustomerServiceConfig,
    },
    {
      provide: CUSTOMER_OPTION_VALUE_SETTING_SERVICE_CONFIG_TOKEN,
      useValue: {
        environment: redirectDevToTest(environment.name),
      } as CustomerOptionValueSettingServiceConfig,
    },
    {
      provide: CUSTOMER_FREEFORM_SETTING_SERVICE_CONFIG_TOKEN,
      useValue: {
        environment: redirectDevToTest(environment.name),
      } as CustomerFreeformSettingServiceConfig,
    },
    {
      provide: SETTING_SERVICE_CONFIG_TOKEN,
      useValue: {
        environment: redirectDevToTest(environment.name),
      } as SettingServiceConfig,
    },
  ]);
