import { enableProdMode, importProvidersFrom } from '@angular/core';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import { browserTracingIntegration, init, replayIntegration } from '@sentry/angular';
import { AppRouteReuseStrategy } from './app/app-route-reuse-strategy';
import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';
import { provideCore } from './app/core/providers/core.providers';
import { environment } from './environments/environment';

//Sentry was initialized using this guide:
//https://docs.sentry.io/platforms/javascript/guides/angular/
if (environment.name !== 'LOCAL') {
  // This is a workaround to prevent the replay integration from spamming
  // the console with "Unable to preventDefault inside passive event listener invocation"
  // when things are being dragged around.
  // https://github.com/angular/angular/issues/45020#issuecomment-1097905250
  document.addEventListener(
    'mousemove',
    () => {
      /* empty */
    },
    { passive: false, capture: true },
  );

  init({
    dsn: 'https://6c58be80e268a97e3c1bb28bee1db4d8@o430870.ingest.sentry.io/4505630255349760',
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      browserTracingIntegration(),
      // Registers the Replay integration,
      // which automatically captures Session Replays
      replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https?:\/\/.*\.garmin\.com/],

    // Capture Replay for 0% of all sessions,
    replaysSessionSampleRate: 0.0,
    // plus for 100% of sessions with an error
    replaysOnErrorSampleRate: 1.0,
    environment: environment.name,
  });
}

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserModule),
    {
      provide: RouteReuseStrategy,
      useClass: AppRouteReuseStrategy,
    },
    provideCore(),
    provideRouter(routes),
    provideAnimations(),
  ],
}).catch((err) => console.error(err));
