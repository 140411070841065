import type { Routes } from '@angular/router';
import { AuthGuard, subscriptionGuard } from '@garmin-avcloud/avcloud-web-utils';
import { flightOrchestratorFlagsGuard } from './core/guards/flight-orchestrator-flags/flight-orchestrator-flags.guard';
import { sessionGuard } from './core/guards/session-guard/session-guard';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadComponent: () => import('./features/landing/landing.component').then((m) => m.LandingComponent),
  },
  {
    canActivate: [sessionGuard, AuthGuard, subscriptionGuard, flightOrchestratorFlagsGuard],
    path: 'aircraft',
    loadChildren: () => import('./features/aircraft/aircraft-routes'),
    data: {
      featureType: 'aircraft',
      redirectTo: '',
    },
  },
  {
    canActivate: [sessionGuard, AuthGuard, subscriptionGuard, flightOrchestratorFlagsGuard],
    path: 'flights',
    loadChildren: () => import('./features/flights/flights.routes').then((m) => m.routes),
    data: {
      featureType: 'flights',
      redirectTo: '',
    },
  },
  {
    canActivate: [sessionGuard, AuthGuard, subscriptionGuard],
    path: 'settings',
    loadChildren: () => import('./features/settings/settings.routes').then((m) => m.routes),
  },
  {
    path: '**',
    pathMatch: 'full',
    loadComponent: () => import('./features/error/pages/not-found/not-found.component').then((m) => m.NotFoundComponent),
  },
];
